<template>
  <v-app-bar flat v-bind:color="theme.current.colors.background">
    <v-container class="fill-height d-flex" fluid>
      <v-app-bar-title v-bind:class="toText(theme.current.dark)" @click="$router.push('/#intro')">
        {{ person.surname + ' ' + person.name }}
      </v-app-bar-title>

      <v-btn v-for="nav in navItems"
             :key="nav"
             v-bind:class="toText(theme.current.dark)"
             variant="text" @click="$router.push(nav.path)"
      >
        {{ nav.title }}
      </v-btn>
    </v-container>
  </v-app-bar>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  computed: {
    ...mapGetters(['person']),
    theme() {
      return this.$vuetify.theme
    }
  },
  name: "HeaderComponent",
  data: () => ({
    navItems: [
      {title: 'Story', path: '#story'},
      {title: 'Projekte', path: '#projekte'},
    ],
  }),
  methods: {
    toText(dark) {
      if (dark) {
        return "text-white";
      } else {
        return "text-black";
      }
    },
  }
}
</script>

<style scoped>

</style>