<template>
  <v-app id="app">
    <div id="home">
      <HeaderComponent></HeaderComponent>
      <v-main>
        <router-view/>
      </v-main>
      <FooterComponent></FooterComponent>
    </div>
  </v-app>
</template>

<style>
</style>
<script>
import HeaderComponent from "@/components/HeaderComponent";
import FooterComponent from "@/components/FooterComponent";
import {useTheme} from "vuetify";

export default {
  setup() {
    const theme = useTheme()

    return {
      theme,
      toggleTheme: () => theme.global.name.value = theme.global.current.value.dark ? 'light' : 'dark'
    }
  },
  mounted() {
    this.initializeTheme();
    window
        .matchMedia("(prefers-color-scheme: dark)")
        .addEventListener("change", () => {
          this.toggleTheme();
        });
  },
  data: () => ({}),
  methods: {
    initializeTheme() {
      if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
        useTheme().global.name.value = "dark";
      } else {
        useTheme().global.name.value = "light";
      }
    },
  },
  components: {
    HeaderComponent,
    FooterComponent,
  }
}
</script>