import {createRouter, createWebHistory} from 'vue-router'
import PortfolioView from "@/views/PortfolioView";
import TestView from "@/views/TestView";

const routes = [
    {
        path: '/',
        name: 'home',
        component: PortfolioView
    },
    {
        path: `/test`,
        name: `TestView`,
        component: TestView
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior: function (to) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: 'smooth',
            }
        } else {
            return {el: document.getElementById('home'), behavior: 'smooth'}
        }
    }
})

export default router
