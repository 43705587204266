<template>
  <v-card color="white" elevation="0">
    <v-card-title>Story</v-card-title>
    <v-card-item>
      <JavaCodeComponent></JavaCodeComponent>
    </v-card-item>
  </v-card>
</template>

<script>

import JavaCodeComponent from "@/components/JavaCodeComponent";

export default {
  name: 'TestView',
  components: {
    JavaCodeComponent,
  }
}
</script>