// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import {createVuetify} from 'vuetify'

const light = {
    dark: false,
    colors: {
        background: 'rgb(228,231,231)',
        surface: 'rgb(255,255,255,0.5)',
        primary: 'rgb(255,255,255)',
        'primary-darken-1': '#3700B3',
        secondary: '#03DAC6',
        'secondary-darken-1': '#018786',
        error: '#B00020',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
    },
}

const dark = {
    dark: true,
    colors: {
        background: 'rgb(30,30,30)',
        surface: 'rgb(30,30,30,0.5)',
        primary: 'rgb(255,255,255,1)',
        'primary-darken-1': '#3700B3',
        secondary: '#03DAC6',
        'secondary-darken-1': '#018786',
        error: '#B00020',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
    },
}

export default createVuetify({
    theme: {
        defaultTheme: 'light',
        themes: {
            light,
            dark,
        },
        dark: dark,
        light: light,
    },
})
