<template>
  <div id="scriptContainer">
    <v-btn @click="animateText">Klick me</v-btn>
    <div class="line"><span class="Anweisungen">public class </span><span> TimFöhrs </span><span
        class="Anweisungen">extends</span><span>
      Person{</span>
    </div>
    <div class="line" style="padding-left: 25px"><span class="Anweisungen">public </span><span
        class="String">String</span> <span
        class="Variable">geburtsdatum</span><span> = </span><span class="Value">"12.02.2003"</span><span>;</span>
    </div>
    <div class="line" style="padding-left: 25px"><span class="Anweisungen">public </span><span
        class="String">String</span> <span
        class="Variable">telefonnummer</span><span> = </span><span class="Value">"023113790634"</span><span>;</span>
    </div>
    <div class="line" style="padding-left: 25px"><span class="Anweisungen">public </span><span
        class="String">String</span> <span
        class="Variable">e_mail_Adress</span><span> = </span><span
        class="Value">"mail@tim-foehrs.de"</span><span>;</span>
    </div>
    <div class="line" style="padding-left: 25px"><span class="Anweisungen">public static void</span> <span
        class="Method">main</span><span>(</span><span
        class="String">String</span><span>[] </span><span class="standartVariable">args</span><span>){</span>
    </div>
    <div class="line" style="padding-left: 50px">
      <div class="line"><span class="Anweisungen">switch</span><span>(</span><span
          class="Variable">day</span><span>){</span></div>
    </div>
    <div class="line" style="padding-left: 75px"><span class="Anweisungen">case</span> <span
        class="Value">'Monday'</span><span>:</span>
    </div>

    <div class="line" style="padding-left: 100px"><span class="Method">drinkCoffe</span><span>(</span><span
        class="Variable">much</span><span>);</span>
    </div>


    <div class="line" style="padding-left: 75px"><span class="Anweisungen">case</span> <span
        class="Value">'Tuesday'</span><span>:</span>
    </div>


    <div class="line" style="padding-left: 100px"><span class="Method">study</span><span>();</span></div>
    <div class="line" style="padding-left: 100px"><span class="Method">meetWithFriends</span><span>();</span></div>


    <div class="line" style="padding-left: 75px"><span class="Anweisungen">case</span> <span
        class="Value">'Wednesday'</span><span>:</span>
    </div>


    <div class="line" style="padding-left: 100px"><span class="Method">work</span><span>();</span></div>
    <div class="line" style="padding-left: 100px"><span class="Method">playBass</span><span>();</span></div>


    <div class="line" style="padding-left: 75px"><span class="Anweisungen">case</span> <span
        class="Value">'Thursday'</span><span>:</span>
    </div>


    <div class="line" style="padding-left: 100px;"><span class="Method">programmArduino</span><span>();</span></div>
    <div class="line" style="padding-left: 100px;"><span class="Method">eatHealthy</span><span>();</span></div>


    <div class="line" style="padding-left: 75px"><span class="Anweisungen">case</span> <span
        class="Value">'Friday'</span><span>:</span>
    </div>


    <div class="line" style="padding-left: 100px;"><span class="Method">planProjekts</span><span>(</span><span
        class="Variable">ground-breaking</span><span> = </span><span class="Boolean">true</span><span>);</span>
    </div>


    <div class="line" style="padding-left: 75px"><span class="Anweisungen">case</span> <span
        class="Value">'Saturday'</span><span>:</span>
    </div>


    <div class="line" style="padding-left: 100px;"><span class="Method">read</span><span>();</span></div>
    <div class="line" style="padding-left: 100px;"><span class="Method">streamMovies</span><span>();</span></div>

    <div class="line" style="padding-left: 75px"><span class="Anweisungen">case</span> <span
        class="Value">'Sunday'</span><span></span><span>:</span>
    </div>

    <div class="line" style="padding-left: 100px;"><span class="Method">eatBreakfast</span><span>();</span></div>
    <div class="line" style="padding-left: 100px;"><span class="Method">take_a_walk</span><span>();</span></div>
    <div class="line" style="padding-left: 100px;"><span class="Method">nJoySUN</span><span>();</span></div>


    <div class="line" style="padding-left: 50px;"><span>}</span></div>


    <div class="line" style="padding-left: 25px;"><span>}</span></div>

    <div class="line"><span>}</span></div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  mounted() {
    this.initialize();
  },
  computed: {
    ...mapGetters(['person']),
  },
  name: "JavaCodeComponent",
  data: () => ({}),
  methods: {
    // Funktion zum schrittweisen Anzeigen des Textes
    initialize() {
      var lines = document.querySelectorAll('.line');

      lines.forEach(function (line) {
        let spans = line.querySelectorAll('span');
        spans.forEach(function (span) {
          span.textContent = '';
        });
      });
    },
    animateText() {
      var lines = document.getElementsByClassName("line");
      let div = document.createElement("div");
      div.innerHTML = this.person.aboutMe[0];
      var charIndex = 0;

      for (let i = 0; i < div.childNodes.length; i++) {
        console.log(i + ": " + div.childNodes.item(i).textContent);
      }
      var copyLines = div.childNodes;

      for (let index = 0; index < copyLines.length; index++) {
        let copyLine = copyLines.item(index);
        let spans = copyLine.childNodes;
        for (let i = 0; i < spans.length; i++) {
          let span = spans.item(i);
          let finalSpan = document.createElement("span");
          lines.item(index).append(finalSpan);
          console.log(copyLine);
          let interval = setInterval(function () {
            //iterate over every char in span
            let text = span.textContent;
            var output = "";
            console.log("chars" + charIndex + " index" + index + " text:" + text);

            if (charIndex < text.length) {
              output = text.substring(0, charIndex + 1);
              console.log(output);
              finalSpan.innerHTML = output;
              charIndex++;
            } else {
              charIndex = 0;
            }

            if (i >= text.length) {
              clearInterval(interval);
            }

          }, 100); // Geschwindigkeit in Millisekunden zwischen den Zeichen
        }
        index++;
      }
    }
  },
}
</script>
<style scoped>
div[class="line"] {
  display: block;
  margin: 0;
  padding: 0;
}

.Anweisungen {
  color: chocolate;
}

.Value {
  color: darkolivegreen;
}

.Method {
  color: darkkhaki;
}

.Variable {
  color: darkorchid;
}

.Boolean {
  color: dodgerblue;
}

.standartVariable {
  color: aqua;
}

.String {
  color: mediumaquamarine;
}

#scriptContainer {
  font-family: monospace;
  overflow: hidden;
  word-wrap: break-word;
  white-space: nowrap;
  /*animation: typing 4s steps(48) forwards;*/
}

.line {
}
</style>