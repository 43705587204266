<template>
  <div class="mb-5">
    <div v-if="dev" class="devBanner">
        <span v-for="(item, index) in 10" :key="index" class="mx-10">Work in Progress!<span v-if="!dev">{{
            item
          }}</span></span>
    </div>
    <div class="maindiv">
      <Particles id="tsparticles" :options="particlesOptions" :particlesInit="particlesInit"/>
      <div class="intro d-flex align-center justify-center">
        <div id="intro"
             style="height: 0; position: relative; top: -200px; pointer-events: none;">
        </div>
        <v-row class="d-flex justify-center my-1 my-sm-3">
          <v-col cols="10" lg="8">
            <v-card color="transparent" elevation="0">
              <v-card-item>
                <v-card-text id="welcome" class="text-md-h3 text-h4" v-bind="person"
                             v-bind:class="toText(theme.current.dark)">
                  Hi,
                  <br>
                  {{ person.welcomeText }}
                </v-card-text>
              </v-card-item>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-if="person.aboutMe.length !== 0" class="story">
        <div id="story" style="height: 0; position: relative; top: -90px; pointer-events: none;"></div>
        <div class="About">
          <v-row class="d-flex justify-center my-1 my-sm-3">
            <v-col cols="10" lg="8">
              <v-card v-bind:color="theme.current.colors.surface">
                <v-card-title v-bind:class="toText(theme.current.dark)">About Me
                </v-card-title>
                <v-card-item>
                  <v-row class="d-flex align-center">
                    <v-col cols="12" sm="4">
                      <v-card v-bind:color="theme.current.colors.surface">
                        <v-img lazy-src="../assets/MeHQLazy.jpg" src="../assets/MeHQ.jpeg">
                          <template v-slot:placeholder>
                            <div class="d-flex align-center justify-center fill-height">
                              <v-progress-circular
                                  color="grey-lighten-4"
                                  indeterminate
                              ></v-progress-circular>
                            </div>
                          </template>
                        </v-img>
                      </v-card>
                    </v-col>
                    <v-col cols="12" sm="8">
                      <v-card elevation="0" v-bind:color="theme.current.colors.surface">
                        <v-card-title class="d-flex justify-center" v-bind="person"
                                      v-bind:class="toText(theme.current.dark)">
                          Hi, ich bin {{ person.surname }} {{ person.name }}
                        </v-card-title>
                        <v-list v-bind:class="toText(theme.current.dark)" v-bind:color="theme.current.colors.surface">
                          <v-list-item prepend-icon="mdi-circle-small">
                            Ich bin {{ berechneAlter(person.birthdate) }} Jahre alt.
                          </v-list-item>
                          <v-list-item prepend-icon="mdi-circle-small">
                            Meine Telefonnummer ist:
                            <a v-bind:class="toText(theme.current.dark)" v-bind:href="`tel:${person.telephone}`">{{
                                person.telephone
                              }}</a>
                          </v-list-item>
                          <v-list-item prepend-icon="mdi-circle-small">
                            Sie erreichen mich unter der Email:
                            <a v-bind:class="toText(theme.current.dark)"
                               v-bind:href="`mailto:${person.email}`">{{ person.email }}</a>
                          </v-list-item>
                        </v-list>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-card-item>
                <v-card class="my-2" elevation="0">
                  <v-row class="d-flex justify-center align-center">
                    <v-tabs v-model="selectedTab">
                      <v-tab v-for="tab in person.hobbies" :key="tab.id" :value="tab.id"
                             class="text-md-h5 text-h6" v-bind:class="toText(theme.current.dark)">
                        <icon v-bind:icon="tab.icon"></icon>
                      </v-tab>
                    </v-tabs>
                    <v-col cols="12">
                      <v-window v-model="selectedTab">
                        <v-window-item v-for="tab in person.hobbies" :key="tab.id" :value="tab.id">
                          <v-card>
                            <v-card-item v-bind:class="toText(theme.current.dark)">
                              <p>{{ tab.text }}</p>
                            </v-card-item>
                          </v-card>
                        </v-window-item>
                      </v-window>
                    </v-col>
                  </v-row>
                </v-card>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <v-row class="d-flex justify-center my-1 my-sm-3">
          <v-col cols="10" lg="8">
            <v-card v-if="dev" elevation="0">
              <v-card-title>Story</v-card-title>
              <v-card-text>
                <JavaCodeComponent></JavaCodeComponent>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-if="person.myProjects.length !== 0" class="projekte">
        <div id="projekte" style="height: 0; position: relative; top: -80px; pointer-events: none;"></div>
        <v-row class="d-flex justify-center">
          <v-col cols="10" lg="8">
            <v-card v-bind:color="theme.current.colors.surface">
              <v-card-title v-bind:class="toText(theme.current.dark)">Projekt</v-card-title>
              <v-card v-for="(projekt, index) in person.myProjects" :key="index" class="ml-md-16 px-5 mb-md-5"
                      color="transparent"
                      elevation="0"
                      @click="navigateTo(projekt.path);">
                <v-card-title v-bind:class="toText(theme.current.dark)">&lt; {{ projekt.name }} &gt;</v-card-title>
                <v-row class="d-flex align-center">
                  <v-col cols="12" md="6">
                    <v-img :src='projekt.preview'>
                      <template v-slot:placeholder>
                        <div class="d-flex align-center justify-center fill-height">
                          <v-progress-circular
                              color="grey-lighten-4"
                              indeterminate
                          ></v-progress-circular>
                        </div>
                      </template>
                    </v-img>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-card-text v-bind:class="toText(theme.current.dark)">
                      <h2>{{ projekt.title }}</h2>
                      <p>
                        {{ projekt.description }}
                      </p>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import {Icon} from '@iconify/vue';
import {mapGetters} from "vuex";
import JavaCodeComponent from "@/components/JavaCodeComponent";
import ChatGPTComponent from "@/components/ChatGPTComponent";
import {loadLinksPreset} from "tsparticles-preset-links";
import {Engine} from "tsparticles-engine";
import {loadAngleUpdater} from "tsparticles-updater-angle";
import {loadBaseMover} from "tsparticles-move-base";
import {loadCircleShape} from "tsparticles-shape-circle";
import {loadColorUpdater} from "tsparticles-updater-color";
import {loadExternalAttractInteraction} from "tsparticles-interaction-external-attract";
import {loadExternalBounceInteraction} from "tsparticles-interaction-external-bounce";
import {loadExternalBubbleInteraction} from "tsparticles-interaction-external-bubble";
import {loadExternalConnectInteraction} from "tsparticles-interaction-external-connect";
import {loadExternalGrabInteraction} from "tsparticles-interaction-external-grab";
import {loadExternalPauseInteraction} from "tsparticles-interaction-external-pause";
import {loadExternalPushInteraction} from "tsparticles-interaction-external-push";
import {loadExternalRemoveInteraction} from "tsparticles-interaction-external-remove";
import {loadExternalRepulseInteraction} from "tsparticles-interaction-external-repulse";
import {loadImageShape} from "tsparticles-shape-image";
import {loadLifeUpdater} from "tsparticles-updater-life";
import {loadLineShape} from "tsparticles-shape-line";
import {loadOpacityUpdater} from "tsparticles-updater-opacity";
import {loadOutModesUpdater} from "tsparticles-updater-out-modes";
import {loadParallaxMover} from "tsparticles-move-parallax";
import {loadParticlesAttractInteraction} from "tsparticles-interaction-particles-attract";
import {loadParticlesCollisionsInteraction} from "tsparticles-interaction-particles-collisions";
import {loadParticlesLinksInteraction} from "tsparticles-interaction-particles-links";
import {loadPolygonShape} from "tsparticles-shape-polygon";
import {loadSizeUpdater} from "tsparticles-updater-size";
import {loadSquareShape} from "tsparticles-shape-square";
import {loadStarShape} from "tsparticles-shape-star";
import {loadStrokeColorUpdater} from "tsparticles-updater-stroke-color";
import {loadTextShape} from "tsparticles-shape-text";

export default {
  /* eslint-disable */
  mounted() {
    this.particlesInit(Engine);
    document.getElementById("home").scrollTo(0, 0);
    this.selectedTab = -1;
    //this.hangMan();
  },
  computed: {
    ...mapGetters(['person']),
    theme() {
      return this.$vuetify.theme
    },
  },
  name: "PortfolioView",
  data: () => ({
    dev: false,
    selectedTab: 0,
    particlesOptions: {
      background: {
        color: {
          value: '#1688e5'
        }
      },
      fpsLimit: 60,
      interactivity: {
        detectsOn: 'window',
        events: {
          onClick: {
            enable: true,
            mode: 'repulse'
          },
          onHover: {
            enable: true,
            mode: 'repulse'
          },
          resize: true
        },
        modes: {
          bubble: {
            distance: 400,
            duration: 2,
            opacity: 0.8,
            size: 40,
            speed: 3
          },
          push: {
            quantity: 4
          },
          repulse: {
            distance: 50,
            duration: 0.4
          }
        }
      },
      particles: {
        color: {
          value: '#ffffff'
        },
        links: {
          color: '#ffffff',
          distance: 150,
          enable: true,
          opacity: 0.5,
          width: 1
        },
        collisions: {
          enable: true
        },
        move: {
          direction: 'none',
          enable: true,
          outMode: 'bounce',
          random: false,
          speed: 1,
          straight: false
        },
        number: {
          density: {
            enable: true,
            value_area: 800
          },
          value: 80
        },
        opacity: {
          value: 0.5
        },
        shape: {
          type: 'circle'
        },
        size: {
          random: true,
          value: 5
        }
      },
      detectRetina: true
    },
  }),
  components: {
    ChatGPTComponent,
    // eslint-disable-next-line
    Icon,
    JavaCodeComponent,
  },
  methods: {
    async particlesInit(engine) {
      await loadLinksPreset(engine);
      await loadBaseMover(engine);
      await loadParallaxMover(engine);

      await loadExternalAttractInteraction(engine);
      await loadExternalBounceInteraction(engine);
      await loadExternalBubbleInteraction(engine);
      await loadExternalConnectInteraction(engine);
      await loadExternalGrabInteraction(engine);
      await loadExternalPauseInteraction(engine);
      await loadExternalPushInteraction(engine);
      await loadExternalRemoveInteraction(engine);
      await loadExternalRepulseInteraction(engine);

      await loadParticlesAttractInteraction(engine);
      await loadParticlesCollisionsInteraction(engine);
      await loadParticlesLinksInteraction(engine);

      await loadCircleShape(engine);
      await loadImageShape(engine);
      await loadLineShape(engine);
      await loadPolygonShape(engine);
      await loadSquareShape(engine);
      await loadStarShape(engine);
      await loadTextShape(engine);

      await loadLifeUpdater(engine);
      await loadOpacityUpdater(engine);
      await loadSizeUpdater(engine);
      await loadAngleUpdater(engine);
      await loadColorUpdater(engine);
      await loadStrokeColorUpdater(engine);
      await loadOutModesUpdater(engine);
    },
    navigateTo(path) {
      window.location.replace('https://' + path);
    },
    toText(dark) {
      if (dark) {
        return "text-white";
      } else {
        return "text-black";
      }
    },
    berechneAlter(datumString) {
      // Splitte den übergebenen String in Tag, Monat und Jahr
      var datumTeile = datumString.split('.');
      var geburtsTag = parseInt(datumTeile[0], 10);
      var geburtsMonat = parseInt(datumTeile[1], 10);
      var geburtsJahr = parseInt(datumTeile[2], 10);

      // Erstelle ein Date-Objekt für das Geburtsdatum und das aktuelle Datum
      var geburtsDatum = new Date(geburtsJahr, geburtsMonat - 1, geburtsTag);
      var aktuellesDatum = new Date();

      // Berechne das Alter
      var alter = aktuellesDatum.getFullYear() - geburtsDatum.getFullYear();

      // Überprüfe, ob der Geburtstag im aktuellen Jahr bereits stattgefunden hat
      if (
          aktuellesDatum.getMonth() < geburtsDatum.getMonth() ||
          (aktuellesDatum.getMonth() === geburtsDatum.getMonth() &&
              aktuellesDatum.getDate() < geburtsDatum.getDate())
      ) {
        alter--;
      }

      // Gib das Alter zurück
      return alter;
    }
  },
}
</script>

<style scoped>
.devBanner {
  background-color: red;
  height: 100px;
  width: 120vw;
  position: fixed;
  position: -webkit-sticky;
  transform: rotate(25deg);
  transform-origin: 100px 4px;
  z-index: 10;
  text-align: center;
  font-size: 50px;
  overflow: hidden;
  white-space: nowrap;
}

#welcome {
  line-height: 1.5;
}
</style>