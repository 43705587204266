<template>
  <v-footer absolute app height="70px" v-bind:class="toText(theme.current.dark)"
            v-bind:color="theme.current.colors.surface">
    <v-row class="d-flex justify-center">
      <v-col class="text-center" cols="12">©Tim Thorben Föhrs {{ new Date().getFullYear() }}</v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: "FooterComponent",
  computed: {
    theme() {
      return this.$vuetify.theme
    }
  },
  methods: {
    toText(dark) {
      if (dark) {
        return "text-white";
      } else {
        return "text-black";
      }
    },
  }
}
</script>

<style scoped>
</style>