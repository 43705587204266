<template>
  <div>
    <div class="About">
      <v-row class="d-flex justify-center">
        <v-col cols="8">
          <v-card v-bind:color="theme.current.colors.surface">
            <v-card-title v-bind:class="toText(theme.current.dark)">About Me</v-card-title>
            <v-card-item>
              <v-row>
                <v-col cols="4">
                  <v-card v-bind:color="theme.current.colors.surface">
                    <v-img src="../assets/Me.png"></v-img>
                  </v-card>
                </v-col>
                <v-col cols="8">
                  <v-card v-bind:color="theme.current.colors.surface">
                    <v-card-title class="d-flex justify-center" v-bind="person">
                      Hi, ich bin {{ person.sir_name }} {{ person.name }}
                    </v-card-title>
                    <v-list v-bind:color="theme.current.colors.surface">
                      <v-list-item prepend-icon="mdi-circle-small">
                        <v-list-item-title>
                          Ich bin {{ berechneAlter(person.geburtsdatum) }} Jahre alt.
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item prepend-icon="mdi-circle-small">
                        <v-list-item-title>
                          Meine Telefonnummer ist:
                          <a v-bind:href="`tel:${person.telefonnummer}`">{{ person.telefonnummer }}</a>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item prepend-icon="mdi-circle-small">
                        <v-list-item-title>Sie erreichen mich unter der Email:
                          <a v-bind:href="`mailto:${person.email}`">{{ person.email }}</a>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-col>
                <v-col cols="12">
                  <v-row>
                    <v-col class="d-flex justify-center" cols="3">
                      <Icon icon="game-icons:guitar-bass-head"/>
                    </v-col>
                    <v-col class="d-flex justify-center" cols="3">
                      <Icon icon="skill-icons:arduino"/>
                    </v-col>
                    <v-col class="d-flex justify-center" cols="3">
                      <Icon icon="ic:twotone-movie"/>
                    </v-col>
                    <v-col class="d-flex justify-center" cols="3">
                      <Icon icon="emojione-monotone:badminton"/>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-item>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>

import {mapGetters} from "vuex";
import {Icon} from '@iconify/vue';

export default {
  computed: {
    ...mapGetters(['person']),
    theme() {
      return this.$vuetify.theme
    },
  },
  components: {
    Icon
  },
  name: "ChatGPTComponent",
  data: () => ({}),
  methods: {
    toText(dark) {
      if (dark) {
        return "text-white";
      } else {
        return "text-black";
      }
    },
    berechneAlter(datumString) {
      // Splitte den übergebenen String in Tag, Monat und Jahr
      var datumTeile = datumString.split('.');
      var geburtsTag = parseInt(datumTeile[0], 10);
      var geburtsMonat = parseInt(datumTeile[1], 10);
      var geburtsJahr = parseInt(datumTeile[2], 10);

      // Erstelle ein Date-Objekt für das Geburtsdatum und das aktuelle Datum
      var geburtsDatum = new Date(geburtsJahr, geburtsMonat - 1, geburtsTag);
      var aktuellesDatum = new Date();

      // Berechne das Alter
      var alter = aktuellesDatum.getFullYear() - geburtsDatum.getFullYear();

      // Überprüfe, ob der Geburtstag im aktuellen Jahr bereits stattgefunden hat
      if (
          aktuellesDatum.getMonth() < geburtsDatum.getMonth() ||
          (aktuellesDatum.getMonth() === geburtsDatum.getMonth() &&
              aktuellesDatum.getDate() < geburtsDatum.getDate())
      ) {
        alter--;
      }

      // Gib das Alter zurück
      return alter;
    }
  }
}
</script>